import { IAppWillMountHandler } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'

export const ReactLoader = withDependencies(
	[],
	(): IAppWillMountHandler => ({
		appWillMount: async () => {
			await window.reactAndReactDOMLoaded
		},
	})
)
